@font-face {
  font-family: 'Neue Pixel Grotesk';
  src: local('Neue Pixel Grotesk'), url(../fonts/NeuePixelGrotesk_Regular.woff) format('woff');
}


* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue', sans-serif;
}

span {
  font-family: 'Neue Pixel Grotesk';
}

// Media queries

@mixin small {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 1150px) {
    @content;
  }
}

@mixin large {
  @media (min-width: 1151px) {
    @content;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

.nav {
  position: fixed;
  top: 80px;
  right: 40px;
  color: white;
  z-index: 100;

  a {
    display: block;
    text-decoration: none;
    color: white;
    text-align: right;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 500;
    transition: 0.2s;
  }

  a:hover {
    color: blue;
  }
}

h1 {
  font-size: 232px;
  font-weight: 400;
  margin: 0;
  margin-bottom: -80px;

  @include medium {
    font-size: 160px;
  }

  @include small {
    font-size: 60px;
  }
}

h2 {
  font-size: 160px;
  font-weight: 400;
  margin: 0;
  margin-bottom: -60px;

  @include medium {
    font-size: 100px;
    margin-bottom: -25px;
  }

  @include small {
    font-size: 40px;
    margin-bottom: -10px;
  }
}

h3 {
  margin-top: 20px;
  margin-bottom: 100px;
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;

  @include small {
    margin-bottom: 40px;
  }
}

h6 {
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin-top: 100px;
}

.hero {
  text-align: center;
  position: relative;
  margin-top: 200px;
  padding: 100px 30px 30px 0;

  @include small {
    margin-top: 100px;
  }
}

.photo {
  width: 50vh;
  max-width: 700px;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  position: absolute;
  top: 0;
  right: 25%;

  @include small {
    display: none;
  }
}

.marquee-top {
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid white;
  padding: 15px 0;
  width: 100vw;
  background-color: black;
  z-index: 2;
}

.marquee-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid white;
  padding: 15px 0;
  width: 100vw;
  background-color: black;
  z-index: 2;

  @include small {
    display: none;
  }
}

.pseudo-link {
  cursor: pointer;
}

.token {
  padding-top: 60px;

  @include small {
    padding: 0;
  }
}

.diagram {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
}

.boi {
  font-size: 24px;
  text-transform: uppercase;
  width: 180px;
  height: 180px;
  border: 1px solid white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include small {
    font-size: 16px;
    width: 100px;
    height: 100px;
  }
}

.equals {
  font-size: 56px;
}

.hour {
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  width: 180px;
  height: 180px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;

  @include small {
    font-size: 16px;
    width: 120px;
    height: 120px;
  }
}

.intro {
  padding-top: 160px;
  padding-bottom: 160px;

  @include small {
    padding: 20px 0;
  }
}

.intro p {
  font-size: 24px;
  line-height: 36px;
}

.wrapper {
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include small {
    display: block;
  }
}

.col {
  width: 46%;

  @include small {
    width: 100%;
    display: inline-block;
  }
}

.ball {
  background-color: blue;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 100%;
  margin: 10px;
}

.ball-big {
  width: 380px;
  height: 380px;
  font-size: 24px;
  padding: 80px;
}

.ball-med {
  width: 260px;
  height: 260px;
  font-size: 24px;
  padding: 30px;
}

.balls {
  width: 100%;
  margin-bottom: 160px;

  @include small {
    margin-bottom: 40px;
  }
}

.projects {
  padding-bottom: 100px;

  @include small {
    padding-bottom: 40px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

.call-to-action .wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 200px;

  @include small {
    margin-bottom: 40px;
  }
}

.call-to-action h3 {
  font-size: 80px;
  margin-bottom: 0;

  @include small {
    font-size: 36px;
  }
}

.call-to-action h2 {
  font-size: 300px;
  margin-top: -30px;

  @include small {
    font-size: 100px;
    margin-top: -10px;
  }
}

.oval-button {
  color: white;
  font-size: 36px;
  text-transform: uppercase;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 100%;
  padding: 50px 100px;
  transition: 0.2s;
  margin-top: 100px;
  transform: rotate(-10deg);
}

.oval-button:hover {
  background-color: white;
  color: black;
  transform: rotate(0deg);
}

.call-to-action h4 a {
  color: white;
  text-transform: uppercase;
  font-size: 36px;
  text-decoration: none;
  font-weight: 400;
  margin-top: 100px;
  display: block;
  transition: 0.2s;
}

.call-to-action h4 a:hover {
  color: blue;
  transform: scale(1.05);
}

/* PAGE STYLES */

.page {
  text-align: center;
  padding-top: 160px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;

   h1 {
    font-size: 126px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 60px;
  }

  h3 {
    font-size: 66px;
    margin-bottom: 20px;
  }

  span {
    border: 1px solid white;
    border-radius: 100px;
    padding: 20px 26px;
    margin-right: 20px;
  }
}

ul, ol {
  text-align: left;
  display: block;
  list-style: none;
}

ul {
  margin-top: 20px;
}

li {
  font-size: 20px;
  max-width: 700px;
  margin-bottom: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-start;
}

.nopes {
  margin-left: 100px;
  margin-bottom: 40px;
}

.step-one,
.step-two {
  font-size: 24px;
}

.step-two {
  margin-top: 60px;
}

.address-field {
  margin-top: 20px;
  line-height: 16px;
  border: 1px solid white;
  padding: 20px;
  font-family: 'Roboto Mono', monospace;
  letter-spacing: 1px;
}

.qr {
  width: 160px;
  margin-top: 40px;
}

.small-text,
.warning-text {
  max-width: 450px;
  font-size: 14px;
  line-height: 20px;
}

.small-text {
  color: #757488;
  margin-top: 60px;
}

.warning-text {
  color: #F8E71C;
}
